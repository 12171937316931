import {ArrowRight,USAIcon,UKIcon,UAEIcon,IndiaIcon} from "../../assets/images/Index";

export const ContactUsData = {

    Title : "Your satisfaction is our top priority",
    SubTitle : "We pride ourselves on building long-term relationships with our customers, and that starts with providing you with the support you need when you need it. So, if you have any questions or concerns, don’t hesitate to get in touch with us. We’re here to help you in any way we can.",

    TalkHead : "READY TO START?",
    TalkSubHead :"We focus on tech, so you can focus on revenue. Talk to us today.",



    Terms : "By checking the box you agree to be contacted by Honebi by any means of communication, including email or phone, in order to receive the latest updates on Honebi content, products or services.",
    BtnText :"Send Message",
    officesHead : "Our Offices",
    branchs : [
        {
            Icon : IndiaIcon,
            Heading : "INDIRANAGAR, INDIA",
            Addresh :"#231, 13th Cross Road, Indiranagar 2nd Stage, Bengaluru - 560038 INDIA.",
            contact :" +91 80 4215 6999",
            View : "View on Map",
            ViewIcon : ArrowRight,
            ViewLink:"https://goo.gl/maps/bXmb6FdJWTZUoGvP6"
        },
        {
            Icon : USAIcon,
            Heading : "USA",
            Addresh :"HONEYSYS LLC 9980 S 300 W, Suite 200, Sandy UT 84070, Utah,USA.",
            contact :"+1 (385) 275 2777",
            View : "View on Map",
            ViewIcon : ArrowRight,
            ViewLink: "https://goo.gl/maps/LYRMGyULX71LxMAi7"
        },
        {
            Icon : UKIcon,
            Heading : "UK",
            Addresh :"HONEYSYS LTD 71-75, Shelton Street, London, England, WC2H 9JQ UNITED KINGDOM. ",
            contact :"+44 20 3289 9990",
            View : "View on Map",
            ViewIcon : ArrowRight,
            ViewLink :"https://goo.gl/maps/gxQVvRoG9PLtequP7"
        },
        {
            Icon : UAEIcon,
            Heading : "UAE",
            Addresh :"HONEYSYS FZE DTEC Dubai Technology Entrepreneur Campus Dubai Silicon Oasis, Dubai UAE.",
            contact :"+971 55 668 2469",
            View : "View on Map",
            ViewIcon : ArrowRight,
            ViewLink :"https://goo.gl/maps/vcJuftsj1Ekup7Du5"
        },
    ]
}