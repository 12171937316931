import React from 'react'
// organisms 
import Footer from '../../shared/building-blocks/organisms/Footer'
import Navbar from '../../shared/building-blocks/organisms/Navbar'
import { HeroSection } from './components/hero-section';
// styles
import "../../assets/styles/about-us/about-us.css"
// adapters 
import { aboutusData } from "../../adapters/Index";
// chakra ui 
import { Box, Text, Image } from '@chakra-ui/react';
import { ValuesSection } from './components/value-section';
import { TimelineSection } from './components/timeline-section';
import { LeadershipSection } from './components/leadership-section';
import { LandScape } from './components/landscape';
import { FeatureSection } from '../../shared/building-blocks/organisms/reusable-organisms';
const AboutUs = () => {
    return (
        <Box>
            <Navbar />
            <Box className='aboutus-container'>

                {/* Hero Section  */}
                <HeroSection aboutusData={aboutusData} />

                {/* Values Section  */}
                {/* <ValuesSection aboutusData={aboutusData}/> */}
                <FeatureSection icon={true} featureData={aboutusData.valuesSection.cards}/>
               
                {/* landscape section  */}
                {/* <LandScape aboutusData={aboutusData}/> */}

                {/* timeline section  */}
                <TimelineSection aboutusData={aboutusData}/>

                {/* leadership section */}
                <LeadershipSection aboutusData={aboutusData}/>
            </Box>
            <Footer />
        </Box>
    )
}

export default AboutUs