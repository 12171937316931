import { ContactUsData } from "../../adapters/contact-us/contact-us";
import "../../assets/styles/contact-us/contactUs.css";
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import React from "react";

const NewContactUs = () => {
  return (
    <>
      <Navbar />
       <br /> <br />
      <div className="CU-container">
        <p className="CU-main-title">{ContactUsData.Title}</p>
        <p className="CU-main-Subtitle">{ContactUsData.SubTitle}</p>

        <div className="talk-container">
          <p className="talk-TalkHead">{ContactUsData.TalkHead}</p>
          <p className="talk-TalkSubHead">{ContactUsData.TalkSubHead}</p>

          <div className="input-cont">
            <input type="text" className="TalkInput" placeholder="First Name" />
            <br />
            <input type="text" className="TalkInput" placeholder="Last Name" />
            <br />
            <input
              type="text"
              className="TalkInput"
              placeholder="Corporate Email"
            />
            <br />
            <input
              id="text"
              className="TalkInput"
              type="tel"
              name="phone"
              placeholder="Phone Number"
            />
            <br />
            <input type="text" className="TalkInput" placeholder="Company" />
            <br />
            <input type="text" className="TalkInput" placeholder="Job Title" />
            <br />
            <input type="text" className="TalkInput" placeholder="Industry" />
            <br />
            {/* <input type="text" className="TalkInput" placeholder="You are a partner-like Organization" /><br /> */}
            <textarea
              className="textaria"
              placeholder="Comments"
            />
          </div>

          <div className="CU-term-box">
            <input className="CU-term-input" type="checkbox" />{" "}
            &nbsp;&nbsp;&nbsp;&nbsp;
            <p className="CU-term-title">{ContactUsData.Terms}</p>
          </div>

          <div className="CU-btn-box">
            <p className="CU-btn-text">{ContactUsData.BtnText}</p>
          </div>
        </div>
      </div>

      <div className="addresh-cont">
        <p className="addresh-head">{ContactUsData.officesHead}</p>

        <div className="office-box">
          {ContactUsData?.branchs?.map((item, index) => (
            <div className="addresh-Inner-Box">
              <img className="addresh-Inner-Img" src={item.Icon} alt="add" />
              <p className="addresh-Inner-text">{item.Heading}</p>
              <p className="addresh-Inner-addresh">{item.Addresh}</p>
              <p className="addresh-Inner-contact">{item.contact}</p>

              <a href={item.ViewLink} target="_blank" >
              <div className="view-btn-cont">
                <p className="view-btn-text">{item.View}</p>
                <img className="view-right-Img" src={item.ViewIcon} alt="ri" />
              </div>
              </a>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default NewContactUs;
