import React from "react";
import {
  Discriptions,
  GallarySec,
  LeftSec,
  LeftSecBox1,
  LeftSecBox2,
  RightSec,
  Titles,
} from "../../../../assets/styles/commenStyle/GallarySection";
const Gallary = ({ data, name, type }) => {
  return (
    <GallarySec name={name} type={type}> 
      <LeftSec name={name} type={type}>
        <LeftSecBox1 name={name} type={type}>
          <img src={data?.left?.HexagonImg} alt="hx" />
        </LeftSecBox1>
        <LeftSecBox2 name={name} type={type}>
          <img src={data?.left?.icon} alt="" />
          <Titles name={name} type={type}>{data?.left?.heading}</Titles>
          <Discriptions name={name} type={type}>{data?.left?.discription}</Discriptions>
        </LeftSecBox2>
      </LeftSec>
      <RightSec name={name} type={type} className="gallery-img">
        <img src={data?.right?.imgUrl}  alt="mn" />
      </RightSec>
    </GallarySec>
  );
};

export default Gallary;
