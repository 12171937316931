import "../ThemeVariables.css";

import styled from "styled-components";

export const HeroSec = styled.div`
  padding: 5% 0;
  justify-content:center;
  background: ${(props) =>
    (props.name === "DC" &&
      "transparent linear-gradient(180deg, #F1EDFE 0%, #FFE8E0 100%) 0% 0% no-repeat padding-box;") ||
    (props.name === "CM" &&
      " transparent linear-gradient(180deg, #F1EDFE 0%, #FAFFBE 100%) 0% 0% no-repeat padding-box;") ||
    (props.name === "FEB" &&
      "transparent linear-gradient(180deg, #F1EDFE 0%, #FFCCFD 100%) 0% 0% no-repeat padding-box;") ||
    (props.name === "OMS" &&
      "transparent linear-gradient(180deg, #F1EDFE 0%, #DDFFDD 100%) 0% 0% no-repeat padding-box;") ||
      (props.name === "PIM" &&
      "transparent linear-gradient(180deg, #F1EDFE 0%, #74FCD2 100%) 0% 0% no-repeat padding-box;") ||
      (props.name === "B2C" &&
      "linear-gradient(180deg, rgba(244,240,210,1) 35%, rgba(255,236,236,1) 90%);") ||
      (props.name === "B2B" &&
      "transparent linear-gradient(178deg, #D2F4E7 0%, #00989839 100%) 0% 0% no-repeat padding-box;") ||
      (props.name === "OMNI" &&
      "transparent linear-gradient(5deg, #D2EAF4 0%, #3B95FF39 100%) 0% 0% no-repeat padding-box;") ||
      (props.name === "D2C" &&
      "transparent linear-gradient(5deg, #E6CBF7 0%, #96A3FF39 100%) 0% 0% no-repeat padding-box;")  

    }
  font-family: var(--font-family-inter);
  display: flex;
  align-items: center;
  opacity :1;
  margin-top:6vh;


  @media only screen and (min-width: 0px) and (max-width: 600px) {
    flex-direction: column-reverse;
    padding-bottom :100px;
    padding-left: 5%;
    padding-right: 4%;
    height: auto;
  }
  
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    padding-top : 50px;
    padding-bottom :100px;
    padding-left: 8%;
    padding-right: 4%;
    gap : 0%;
    height: auto;
  }

`;

export const LeftBox = styled.div`
  width: 40%;
  // padding-left:15%;
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    width : 95%;
    padding-left:0;
  }
  
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    width: 50%;
  }

`;

export const RightBox = styled.div`
  width: 40%;
  display:flex;
  align-items;flex-start;
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    width : 100%;
    padding: 2rem 0;
  }
`;

export const Title = styled.p`
  color: #191f23;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 14px;
  opacity: 1;
  text-align: center;
  width:fit-content;
  padding: 1rem 0;
  font: normal normal medium 14px/24px Inter;
  letter-spacing: 0px;
  padding: .2rem 1rem;
  margin-bottom: 22px;

  @media only screen and (min-width: 0px) and (max-width: 600px) {
    font-size:16px;
    padding: .2rem 1rem;
    font-weight: 600;
  }
  
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    
  }
`;

export const Heading = styled.p`
  width: 100%;
  font-size: 38px;
  text-align: left;
  font: normal normal bold 38px Inter;
  line-height: 54px;
  color: #191f23;
  opacity: 1;
  margin-bottom: 10px;
  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    font-size:22px;
    line-height:30px;
  }
`;

export const Discription = styled.p`
  width: 85%;
  text-align: left;
  font: normal normal normal 20px/30px Inter;
  color: #75797b;
  opacity: 1;
  margin-bottom: 12%;
  @media only screen and (min-width: 600px) and (max-width: 1000px) {
   font-size:18px; 
  }
`;

export const ButtonBox = styled.div`
  border: none;
  background: #506ff3 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  color: #ffffff;
  font-size: 16px;
  width: fit-content;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 1rem 1rem;
  @media only screen and (min-width: 0px) and (max-width: 1000px) {
    width :65%;
  }

  @media only screen and (min-width: 600px) and (max-width: 1000px) {
    width: 70%;
  }

`;

export const ButtonText = styled.p`
  margin-right: 10px;
`;


  

