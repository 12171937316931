import { Comb, ProductImage, ArrowRightUpLine } from "../../assets/images/Index";

export const HeroSectionData = {
    leftSection: {
        title: "A MACH enabled Digital Commerce Platform ",
        description: "Give your customers the experience they deserve, and watch them bring profits to your business.",
        button: "Learn More"
    },
    rightSection: {
        background: ProductImage,
    },
    background: Comb,
    ArrowRightUpLine
}