import React from 'react'
import "../../assets/styles/use-cases/use-cases.css"
import { usecasesData } from '../../adapters/Index'
import { FiArrowUpRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { use_case_bg } from '../../assets/images/Index';
const UseCases = () => {
    const backgroundStyle = {
        backgroundImage: `url(${use_case_bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "top"
    }
    const navigate = useNavigate()
    return (
        <div id='usecase-container' style={backgroundStyle}>
            <p className='title-section'>{usecasesData.title}</p>
            <div className='title-content'>
                <img src={usecasesData.Pointer} alt="." />
                <p>{usecasesData.heading}</p>
            </div>
            <div className='first-block'>
                {usecasesData.usecaseList1.map((item, index) => {
                    return (
                        <div key={index} className='usecase-card'>
                            <img src={item.icon} alt={item.button} />
                            <p className="usecase-description">{item.description}</p>
                            <button onClick={()=>{
                                navigate(`/${item.button}`);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                });
                            }}>{item.button} <FiArrowUpRight/></button>
                        </div>
                    )
                })}
            </div>
            <div className='second-block'>
                {usecasesData.usecaseList2.map((item) => {
                    return (
                        <div className='usecase-card'>
                            <img src={item.icon} alt={item.button} />
                            <p className="usecase-description">{item.description}</p>
                            <button onClick={()=>{
                                navigate(`/${item.button}`);
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                    behavior: "smooth",
                                })
                            }}>{item.button} <FiArrowUpRight/></button>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default UseCases