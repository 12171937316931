import React from "react";
const OtherFeatures = ({data})=>{
    return (
        <>
            <div className="otherfeatures-container">
                <p className="mainheading-container">
                    { data.otherFeatures.mainHeading }
                </p>
                <div className="allfeatures">
                    {
                        data.otherFeatures.allFeatures.map((feature, index)=>{
                            return (

                                <div className="f-sec-cont">
                                    <div className="f-sec-cont-left">
                                    <img src={data.otherFeatures.HexagonImg} alt="" style={{marginTop:"-15px"}} />
                                    </div>

                                    <div className="f-sec-cont-right">
                                    <img src={feature.icon} alt={feature.heading} className="f-sec-cont-right-img" />
                                     <p className="f-sec-cont-right-title">{feature.heading}</p>
                                     <p className="f-sec-cont-right-subTitle">{feature.description}</p>
                                </div>
                                </div>
                               
                                
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default OtherFeatures