import { Box, Image, Text } from "@chakra-ui/react"
// styles
import "../../../assets/styles/about-us/about-us.css"
import VerticalCarousel from "../../../shared/carousel/carousel"
export const TimelineSection = ({ aboutusData }) => {
    return (
        <Box className='timeline-container'>
            {/* <Box className='timeline'>
                {aboutusData.historySection.timeline.map((time) => {
                    return (
                        <Box className='time'>
                            <Text>{time.timeSpan}</Text>
                            <Box><Image src={time.currentTimeImg} /></Box>
                            <Text>{time.description}</Text>
                        </Box>
                    )
                })}
            </Box> */}
            <VerticalCarousel>
                {aboutusData.historySection.timeline.map((event)=>{
                    return <>{event.description}</>
                })}
            </VerticalCarousel>
            <MobileTimeline aboutusData={aboutusData} />
        </Box>
    )
}

const MobileTimeline = ({ aboutusData }) => {
    return (
        <>
            <Box className='title-content'>
                <Text>
                    {aboutusData.historySection.title}
                </Text>
            </Box>
            {aboutusData.historySection.timeline.map((time) => {
                return (
                    <Box className="MobileTimeline">
                        <Image src={time.currentTimeImg}/>
                        <Box className="text-content">
                            <Text>{time.timeSpan}</Text>
                            <Text>{time.desc}</Text>
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}