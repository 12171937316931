// styles 
import { Box, Image, Text } from "@chakra-ui/react"
import "../../../../assets/styles/building-blocks/atoms/feature-content.css"
import { ReadMore } from "./read-more"
export const FeatureContent = ({feature, icon})=>{
    return (
        <Box className="feature-content-container">
            { icon && <Box><Image src={feature.icon}/></Box>}
            <Box className="heading">
                <Image src={feature.Pointer}/>
                <Text>{feature.heading}</Text>
            </Box>
            <Box className="description">
                <Box></Box>
                <Text>{feature.description}</Text>
            </Box>
            { !icon && <Box  className="read-more">
                <Box></Box>
                <ReadMore/>
            </Box>}
        </Box>
    )
}