import { abstractGradient,Pointer, honecomb,comb, aboutus, honecombPointer, landscape, Profile1, Profile2, hexagons, handshake, handheart, settings, growth, Linkdin } from "../../assets/images/Index";

export const aboutusData = {
    // Hero Section 
    heroSection: {
        title: "OUR VISION",
        description: `To empower retailers in India and across the world with their own First-ever, world- class Unified Retail Operating System.`,
        tag: "Enabling them to take charge their customers’ growth, experiences and profitability.",
        bgImg: hexagons
    },

    // Value Section 
    valuesSection: {
        title: "WHY HONEBI ?",
        heading: "Values that drive our innovation",
        cards: [
            {   
                img: handshake,
                icon: handheart,
                heading: "Not just a Product, we are Partners",
                description:"We understand your needs and bring your vision to fruition.",
                Pointer
            },
            {   
                img: settings,
                icon: growth,
                heading: "Your business, Your rules",
                description:"We mould to your ‘way of doing business.",
                Pointer
            },

        ]
    },

    // landscapesection
    landScape: {
        title: `‘Seamless’ at its best`,
        heading: `Unifying Retail landscape`,
        landscape
    },

    // History Section 
    historySection: {
        title: "OUR HISTORY",
        comb,
        timeline: [
            {
                timeSpan: "2017-18",
                currentTimeImg: honecombPointer,
                desc: "Developed prototype in Dubai",
                description: "2017-18#Developed prototype in Dubai."
            },
            {
                timeSpan: "2018-19",
                currentTimeImg: honecombPointer,
                desc: "Onboarded our first 40 clients in freemium model",
                description: "2018-19#Onboarded our first 40 clients in freemium model."
            },
            {
                timeSpan: "2019-21",
                currentTimeImg: honecombPointer,
                desc:"Honebi came back to India, for a Full-fledged product development and R&D.",
                description: "2019-21#Honebi came back to India, for a Full-fledged product development and R&D."
            },
            {
                timeSpan: "2021-22",
                currentTimeImg: honecombPointer,
                desc: "Acquired first commercial client (BOT).",
                description: "2021-22#Acquired first commercial client (BOT)."
            },
            {
                timeSpan: "2022-23",
                currentTimeImg: honecomb,
                desc:"Acquired first 3 licensing based customer & are confident to acquire another 5 customers by end of the year.",
                description: "2022-23#Acquired first 3 licensing based customer & are confident to acquire another 5 customers by end of the year."
            },
        ]
    },

    leaderSection: {
        title: "LEADERSHIP",
        heading: "Meet the Leaders Behind Our Innovative Commerce Platform.",
        cards: [
            {
                profileImg: Profile1,
                name: "Madan Gandam",
                designation: "Founder & CEO",
                linkedin: "https://www.linkedin.com/in/madankumargandam/",
                about: "Madan’s extensive experience in large-scale business solution implementations has given him valuable insights into the challenges that businesses face in this rapidly evolving digital landscape. His keen eye for recognizing what the market is asking for and businesses need led to the development of’ Honebi’.",
                extension: `‘Our aim is to equip businesses with future ready, innovative RaaS platform solutions that enable them to navigate everyday challenges, exceed evolving customer expectations and stay ahead of the competition in an omnichannel setting. We are committed to improving the ease of doing business through cutting edge technology’. This is where Madan comes from.
                With over two decades of experience in the technology space, Madan has had a front-row seat to the challenges faced by big IT in keeping up with the rapidly-evolving e-commerce landscape. As a Solution Architect with Satyam, Soltius ME, Tech Mahindra, IBM, DEWA, and others, Madan has been instrumental in crafting and implementing large-scale business solutions for market 
                giants such as Sony, Lenovo, Reliance Retail, and many others. Additionally, he has been associated with Dubai Electricity & Water Authority and Dubai Healthcare City, where he brought his expertise to the government sector.
                Madan has always been fascinated by how large retailers have leveraged technology to reach more customers and scale their businesses. However, he questioned whether small and medium-sized businesses had access to the same tools and resources. Were they being limited by the one-size-fits-all solutions offered by available platforms?
                Madan saw an opportunity to create a home-grown, end-to-end digital solution that could address the unique challenges facing small businesses and deliver an unparalleled customer experience. This drive to empower small businesses led Madan to step into this space and create a solution that would help them succeed in the digital age. And ‘Honebi’ was born.
                Born and raised in Nellore, Madan remains passionate about his roots. He is excited to bring Honebi to small retail and kirana stores in his hometown, empowering them to become the businesses of tomorrow.
                `,
                title: "founder"
            },
            {
                profileImg: Profile2,
                name: "Lakshman Sai Narakuchi",
                designation: "Co Founder & CTO",
                linkedin: "https://www.linkedin.com/in/lakshman-sai-narakuchi/",
                about: "Lakshman is all things tech. He has been instrumental in developing and implementing the company's technology roadmap, driving development and evolution of Honebi and other solutions  in line with our vision to deliver added value to customers. ",
                extension: `Lakshman is a highly skilled software developer with a passion for experimentation and has a wide range of experience in different technologies and frameworks. Thanks to his expertise in Java/J2EE and Node platform microservices, as well as his knowledge of  JQuery, Angular JS, React JS, and HTML5, Lakshman is well-versed in both back-end and front-end development.
                Lakshman has been associated with companies such as CGI, Mindtree, Dell and Motorola for two decades. He is a collaborator par excellence and, coupled with his problem solving skills, can tackle complex projects with ease. His steadying calm ensures that his team can translate demands into deliverables within the deadline.
                When not racing against time to ‘go-live’, Lakshman loves to experiment with new software and hardware by tinkering with gadgets and devices to see how they work. He has leveraged emerging technologies like machine learning, artificial intelligence, and blockchain to create innovative solutions for complex business problems faced by customers.                
                `,
                title: "co-founder"
            }
        ],
        Linkdin
    }
}