import React from "react";
import Herosections from "../../shared/building-blocks/molecules/Product-Components/Herosections";
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import { B2cData } from "../../adapters/b2c-commerce/b2c-commerce";

import Gallary from "../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import LearnMoreSolutions from "../../shared/building-blocks/molecules/Solutions-components/LearnMoreSolutions";


const B2CPage = () => {
  return (
    <>
       <Navbar />

<Herosections data={B2cData} name="B2C" />

{B2cData?.Gallary.map((item, index) => (
  <Gallary data={item} name={"B2C"} type={index % 2 == 0 ? "TI" : "IT"} />
))}

<OtherFeatures data={B2cData} name={"B2C"} />
<LearnMoreSolutions data={B2cData} name={"B2C"} />
<Footer />
    </>
  )
}

export default B2CPage;