import React from "react";
import Herosections from "../../shared/building-blocks/molecules/Product-Components/Herosections";
import Footer from "../../shared/building-blocks/organisms/Footer";
import Navbar from "../../shared/building-blocks/organisms/Navbar";
import { B2bData } from "../../adapters/b2b-commerce/b2b-commerce";
import Gallary from "../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import LearnMoreSolutions from "../../shared/building-blocks/molecules/Solutions-components/LearnMoreSolutions";


const B2BPage = () => {
  return (
    <>
       <Navbar />

<Herosections data={B2bData} name="B2B" />

{B2bData?.Gallary.map((item, index) => (
  <Gallary data={item} name={"B2B"} type={index % 2 == 0 ? "TI" : "IT"} />
))}

<OtherFeatures data={B2bData} name={"B2B"} />
<LearnMoreSolutions data={B2bData} name={"B2B"} />
<Footer />
    </>
  )
}

export default B2BPage;