import React from "react";
import {
  ButtonBox,
  ButtonText,
  Discription,
  Heading,
  HeroSec,
  LeftBox,
  RightBox,
  Title,
} from "../../../../assets/styles/commenStyle/HeroSection";
import { FiArrowUpRight } from "react-icons/fi";
import { Link } from "react-router-dom";


const Herosections = ({ data, name ,type}) => {
  return (
    <HeroSec name={name} type={type}>
      <LeftBox>
        <Title>{data?.HeroSection?.left?.title}</Title>
        <Heading>{data?.HeroSection?.left?.heading}</Heading>
        <Discription>{data?.HeroSection?.left?.discription}</Discription>
        <ButtonBox onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }>
            <div>
                <ButtonText>
                     <Link to={"/Contact"}>{data?.HeroSection?.left?.buttonText}</Link>
                </ButtonText>
            </div>
            <div> <FiArrowUpRight size="20" /> </div>
        </ButtonBox>
      </LeftBox>
      <RightBox >
        <img className="hsection-img" src={data?.HeroSection?.right?.imgUrl} alt="main-url" />
      </RightBox>
    </HeroSec>
  );
};

export default Herosections;
