import React, { useState } from 'react'
import "../../../assets/styles/navbar/Navbar.css"
import { NavbarData } from "../../../adapters/Index"
import { Link, useNavigate } from "react-router-dom"
import NavbarDropDown from '../molecules/navbar/navbar-dropdown';
import DropdownMenu from '../molecules/navbar/dropdown-menu';
import { Box, Flex } from '@chakra-ui/layout';
import { RxHamburgerMenu } from 'react-icons/rx';
import { MdClose } from "react-icons/md";
import { Image } from '@chakra-ui/image';
import { useDisclosure } from '@chakra-ui/hooks';

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
} from '@chakra-ui/react'
const Navbar = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [menu, setMenu] = useState([])
  const navigate = useNavigate()
  function handleDropDown(menuItem) {
    setShowDropDown(true);
    setMenu([...NavbarData.menu[menuItem]]);
  }
  return (
    <>
      <div id='navbar-container'>
        {/* left section  */}
        <div className='left-section'>
          <div className='logo-section'>
            <Link to="/"><img src={NavbarData.brand.logo} alt="Honebi" /></Link>
          </div>
          <div className='menu-section'>
            {Object.keys(NavbarData.menu).map((menuItem, index) => {
              if(Object.keys(NavbarData.menu).length-1!==index && NavbarData.menu[`${menuItem}`].length!==0) return <p onMouseOver={() => handleDropDown(menuItem)} key={index}>{menuItem}</p>;
              else return <Link to={`/${menuItem}`}><p onMouseOver={()=>setShowDropDown(false)} key={index}>{menuItem}</p></Link>;
            })}
          </div>
          <div onClick={()=>navigate("/Contact")} style={{cursor:"pointer"}} className='contact-section' >
          <Link to={"/Contact"}><p>Contact us</p></Link>
          </div> 
        </div>
        {/* right section  */}
        {/* <div className='right-section'>
          <div className='chat-bot'>
            <img src={NavbarData.QuickChat.icon} alt="Chat Bot" />
            <p>Quick chat?</p>
          </div>

        </div> */}

        {/* for Smaller Screens  */}
        <MobileNavbar />
        {showDropDown && <NavbarDropDown menu={menu} setShowDropDown={setShowDropDown} />}
      </div>

    </>
  )
}

export default Navbar

const MobileNavbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [placement, setPlacement] = useState('top');
  const navigate = useNavigate()
  return (
    <Flex className='hamburger-menu' p="2% 3%" justifyContent={"space-between"} alignItems={"center"}>

      {/* Hamburger Menu Section  */}
      <Box>
        <Link to="/"><Image w='40%' src={NavbarData.brand.logo} /></Link>
      </Box>

      <Box color='blue' onClick={onOpen} cursor='pointer'>        
      <RxHamburgerMenu fontSize={'1.5rem'} color='blue' />
      </Box>
      <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth='1px' p="0" m='0'>
            <Flex p="2% 3%"  justifyContent={"space-between"} alignItems={"center"}>
            <Image onClick={()=>navigate("/")} w="35%" src={NavbarData.brand.logo} />
              <Box onClick={onClose} cursor='pointer'>
                <MdClose fontSize={'1.5rem'} color='blue'/>
              </Box>
            </Flex>
          </DrawerHeader>
          <DrawerBody>
            <DropdownMenu />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  )
}