import cofounder from "../../assets/images/about-us/SaiProfile.png";
import founder from "../../assets/images/about-us/MadanProfile.png";

export const Cofounder = {
    profileImg: cofounder,
    name: "Lakshman Sai Narakuchi",
    designation: "Co Founder & CTO",
    linkedin: "https://www.linkedin.com/in/lakshman-sai-narakuchi/",
    about: "Lakshman is all things tech. He has been instrumental in developing and implementing the company's technology roadmap, driving development and evolution of Honebi and other solutions  in line with our vision to deliver added value to customers. ",
    extension: `Lakshman is a highly skilled software developer with a passion for experimentation and has a wide range of experience in different technologies and frameworks. Thanks to his expertise in Java/J2EE and Node platform microservices, as well as his knowledge of  JQuery, Angular JS, React JS, and HTML5, Lakshman is well-versed in both back-end and front-end development.
    Lakshman has been associated with companies such as CGI, Mindtree, Dell and Motorola for two decades. He is a collaborator par excellence and, coupled with his problem solving skills, can tackle complex projects with ease. His steadying calm ensures that his team can translate demands into deliverables within the deadline.
    When not racing against time to ‘go-live’, Lakshman loves to experiment with new software and hardware by tinkering with gadgets and devices to see how they work. He has leveraged emerging technologies like machine learning, artificial intelligence, and blockchain to create innovative solutions for complex business problems faced by customers.                
    `
}