import { Box, Image, Text } from "@chakra-ui/react"
// styles
import "../../../assets/styles/about-us/about-us.css";
import { HeroButton } from "../../../shared/building-blocks/atoms";
export const HeroSection = ({aboutusData}) => {
    return (
        <Box className="hero-section" backgroundColor={`#4159BC`}>
            <Box className='text-content'>
                <Text> {aboutusData.heroSection.title} </Text>
                <Text> {aboutusData.heroSection.description} </Text>
                <Text mb={"4rem"}> {aboutusData.heroSection.tag} </Text>
                <HeroButton margin={"auto"} bg={"black"} color={"white"} text={"Request a demo"}/>
                <center><Image w={"70%"} mt={"3rem"} pb={"2rem"} src={aboutusData.heroSection.bgImg}/></center>
            </Box>
        </Box>
    )
}