import "../ThemeVariables.css";

import styled from "styled-components";

export const SolutionTitle = styled.p`
  font-family: var(--font-family-inter);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-191f23);
  text-align: center;
  font: normal normal bold 24px/54px Inter;
  letter-spacing: 0px;
  color: #191f23;
  opacity: 0.8;
  margin-bottom: 5%;
  margin-top: 7%;

     
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    margin-bottom: 20%;
    margin-top: 15%;   
  }
`;

export const Solutions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom :40px;
   
  @media only screen and (min-width: 0px) and (max-width: 600px) {
       grid-template-columns : repeat(1,1fr);
       margin-left:5px;
       margin-right:5px;
  }
`;

export const SolutionInnerBox = styled.div`
  text-aline: center;
  width: 62%;
  margin: auto;
     
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    width: 82%;
  margin: auto;
}
`;
export const SolutionIconBox = styled.div`
  width: 100px;
  margin: auto;
  margin-bottom: 30px;
`;

export const SolutionHead = styled.p`
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-75797b);
  text-align: center;
  font-size: 20px;
  letter-spacing: 0px;
  color: #75797b;
  opacity: 1;
  margin-bottom: 20px;
   
     
  @media only screen and (min-width: 0px) and (max-width: 600px) {
    margin-bottom : 30px;
}
`;

export const SolutionLinkBox = styled.div`
  display: flex;
  margin-bottom: 33%;
  padding-left: 10px;
  padding-top:12px;
  padding-bottom:12px;
  padding-right: 10px;
  border-radius: 40px;
  background: ${(props) =>
    ((props.num == 0 || props.num == 1 || props.num == 3 || props.num == 2) &&
      "#edf0fe;") ||
    (props.num == 2 && "#506FF3 0% 0% no-repeat padding-box;")};
  border: none;

  color: ${(props) =>
    ((props.num == 0 || props.num == 1 || props.num == 3 || props.num == 2) &&
      "#506ff3;") ||
    ((props.num == 2) && "#fff;")};
   justify-content : space-around;
`;



export const SolutionLinkText = styled.p`
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-600)
    var(--unnamed-font-size-18) / 21px var(--unnamed-font-family-inter);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-506ff3);
  text-align: left;
  font: normal normal 600 18px/21px Inter;
  letter-spacing: 0px;
  opacity: 1;
`;
