import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi';
import "../../assets/styles/homePage/FeaturesSection.css";
import { FeaturesSectionData } from '../../adapters/featuresSection/FeaturesSection';
import {Link} from "react-router-dom";
import {NavbarData} from "../../adapters/navbar/Navbar"
const FeaturesSection = () => {
  return (
    <div id='features-container'>
        <div className='section-name'>
          {FeaturesSectionData.title}
        </div>
        <div className='section-heading'>
          {FeaturesSectionData.heading}
        </div>
        <div className='section-subHeading'>
          {FeaturesSectionData.subHeading}
        </div>
        <div className='features-list'>
          {FeaturesSectionData.featuresList.map((feature,index)=>{
            return (
              <div key={index} className='feature-content'>
                <img src={feature.icon} alt={feature.link} />
                <div className='title-content' >
                  <img src={FeaturesSectionData.pointerIcon} alt="." />
                  <p>{feature.title}</p>
                </div>
                <p className='description-content'>{feature.description}</p>
                <p className='explore'
                  onClick={() =>
                    window.scrollTo({
                      top: 0,
                      left: 0,
                      behavior: "smooth",
                    })
                  }
                >
                    <Link style={{display:"flex", justifyContent:"center", alignItems:"center", gap:"1rem"}} to={`${NavbarData.menu["Products"][index]}`}>{feature.link} <FiArrowUpRight/></Link>
                </p>
              </div>
            )
          })}
        </div>
    </div>
  )
}

export default FeaturesSection