import React from "react";
import {
  SolutionHead,
  SolutionIconBox,
  SolutionInnerBox,
  SolutionLinkBox,
  SolutionLinkText,
  Solutions,
  SolutionTitle,
} from "../../../../assets/styles/commenStyle/LearnMoreSolutions";

const LearnMoreSolutions = ({ data }) => {
  return (
    <>
      <SolutionTitle>{data?.Featcherheading}</SolutionTitle>
      <Solutions>
        {data?.LearnMoreSolution?.map((item, index) => (
          <SolutionInnerBox>
            <SolutionIconBox>
              <img src={item?.OrderManagementImg} alt="" />
            </SolutionIconBox>
            <SolutionHead>{item?.FeatcherLeftHead}</SolutionHead>

            <SolutionLinkBox num={index}>
              <SolutionLinkText>{item?.Solution}</SolutionLinkText>
              <div>
                <img src={item?.ArrowRightUpLine} alt="ar" />
              </div>
            </SolutionLinkBox>
          </SolutionInnerBox>
        ))}
      </Solutions>
    </>
  );
};

export default LearnMoreSolutions;
