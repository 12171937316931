import React from "react";
import { Routes, Route } from "react-router-dom";
import { Cofounder, Founder } from "../../adapters/Index";
import { HomePage, FrontendExperience, Careerpage } from "../../pages";
import AboutUs from '../../pages/about-us/about-us'
import { Bio } from "../../pages/bio/bio";
import CommingSoon from '../../pages/comming-soon/comming-soon'
import ContactUs from '../../pages/contact-us/contact-us'
import NewContactUs from "../../pages/new-contact-us/NewContactUs";
import Catelog from "../../pages/ProductPage/Catalog-Management/Catelog";
import DigitalCommerce from "../../pages/ProductPage/DigitalCommerce/DigitalCommerce";
import OrderManagementSystem from "../../pages/ProductPage/OrderManagementSystem/OrderManagementSystem";
import ProductInformationManagement from "../../pages/ProductPage/product-Information-management/ProductInformationManagement";
import B2BPage from "../../pages/solution-pages/B2B-Commerce";
import B2CPage from "../../pages/solution-pages/B2C-Commerce";
import D2CPage from "../../pages/solution-pages/D2C-commerce";
import OmnichannelPage from "../../pages/solution-pages/Omnichannel";
import TermAndService from "../../pages/term-and-service/TermAndService";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path="/Digital Commerce Platform" element={<DigitalCommerce />} />
      <Route path="/Order Management System" element={<OrderManagementSystem />} />
      <Route path="/Front-End experience builder(CMS)" element={<FrontendExperience />} />
      <Route path="/Catalog Management" element={<Catelog />} />
      <Route path="/Product Information Management" element={<ProductInformationManagement />} />

      {/* solutions  */}
      <Route path='/B2C Commerce' element={<B2CPage />} />
      <Route path='/B2B Commerce' element={<B2BPage />} />
      <Route path='/D2C Commerce' element={<D2CPage />} />
      <Route path='/Marketplace Solution' element={<CommingSoon />} />
      <Route path='/Omnichannel Solution' element={<OmnichannelPage />} />
      <Route path="/About Us" element={<AboutUs />} />
      {/* <Route path='Contact' element={<ContactUs />} /> */}
      <Route path="Contact" element={<NewContactUs />} />
      <Route path="Contact Us" element={<NewContactUs />} />
      <Route path='Comming Soon' element={<CommingSoon />} />

      {/* Industries  */}
      <Route path='/industries' element={<CommingSoon />} />

      {/* Tools  */}
      <Route path='Sales Assistance Solution' element={<CommingSoon />} />
      <Route path='Customer 360 Solution' element={<CommingSoon />} />
      <Route path='In Store VM Solution' element={<CommingSoon />} />
      <Route path='Van POS Solution' element={<CommingSoon />} />

      {/* Editions  */}
      <Route path='Editions' element={<CommingSoon />} />

      <Route
        path="/PIM"
        element={<ProductInformationManagement />}
      />
      <Route path="/Compliance" element={<CommingSoon />} />
      <Route path="/Security" element={<CommingSoon />} />
      <Route path="/Site map" element={<CommingSoon />} />

      {/* Footer Routes  */}
      <Route path='/Customers' element={<CommingSoon />} />
      <Route path='/Press & media' element={<CommingSoon />} />
      <Route path='/Investor Relations' element={<CommingSoon />} />
      <Route path='/Careers' element={<Careerpage />} />
      <Route path='/Contact Us' element={<ContactUs />} />
      <Route path='/Honebi' element={<HomePage />} />
      <Route path="/Honebi Enterprise" element={<CommingSoon />} />
      <Route path="/Front end experience builder" element={<FrontendExperience />} />

      <Route path="/Terms and Conditions" element={<TermAndService />} />
      <Route path="/Privacy policy" element={<TermAndService />} />
      <Route path="/Legal" element={<TermAndService />} />

      {/* bio pages */}
      <Route path="founder" element={<Bio card={Founder} />} />
      <Route path="co-founder" element={<Bio card={Cofounder} />} />
      
    </Routes>
  );
};

export default AllRoutes;
