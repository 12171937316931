import { Box } from "@chakra-ui/react"
// styles 
import "../../../../assets/styles/building-blocks/organisms/special-section.css";
import { MobileContent, SpecialContent, SpecialImage } from "../../atoms";
export const SpecialSection = ({specialData})=>{
    return (
        <Box className="special-section-container">
            { specialData.map((section,index)=>{
                if(index%2===0){
                    return (
                        <Box className="left-section" key={index}>
                            <SpecialImage src={section.img}/>
                            <SpecialContent text={section}/>
                            {/* <MobileContent text={section}/> */}
                        </Box>
                    )
                }else return (
                        <Box color="whitesmoke" className="right-section" key={index}>
                            {/* <MobileContent color="white" text={section}/> */}
                            <SpecialContent color={"white"} text={section}/>
                            <SpecialImage src={section.img}/>
                        </Box>
                )
            }) }
        </Box>
    )
}