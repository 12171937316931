import {
  HexagonImg,
  ArrowRightUpLine,
  B2cSolutionIcon1,
  B2cSolutionIcon2,
  B2cSolutionIcon3,
  B2cSolutionIcon4,
  B2bMainImg,B2bGallaryImg1,B2bGallaryImg2,B2bGallaryImg3,B2bGallaryIcon1,B2bGallaryIcon2,B2bGallaryIcon3,
  B2bIcon1,B2bIcon2,B2bIcon3
} from "../../assets/images/Index";

export const B2bData = {
  // hero section
  HeroSection: {
    left: {
      title: "B2B Commerce",
      heading: "Streamline B2B operations",
      discription:
        "Forge customized commerce experiences that are specifically designed. Transform the way you interact with buyers, suppliers, and channel partners.",
      buttonText: "Request a demo",
      arrowUpIcon: ArrowRightUpLine,
    },

    right: {
      imgUrl: B2bMainImg,
    },
  },

  Gallary: [
    {
      left: {
        HexagonImg: HexagonImg,
        icon: B2bGallaryIcon1,
        heading: "Enable unassisted purchasing",
        discription:
          "Facilitate customers to quickly and easily place orders without having to wait for a sales representative, reducing the time to order fulfillment.",
      },
      right: {
        imgUrl: B2bGallaryImg1,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: B2bGallaryIcon2,
        heading: "Personalize offerings for every customer",
        discription:
          "Gain competitive advantage by providing custom, differentiating quotes to buyers and channel partners in a crowded market.",
      },
      right: {
        imgUrl: B2bGallaryImg2,
      },
    },
    {
      left: {
        HexagonImg: HexagonImg,
        icon: B2bGallaryIcon3,
        heading: "Increase operations efficiency",
        discription:
          "Automate processes, such as order processing, inventory management, and shipping. Reduce the need for manual intervention.",
      },
      right: {
        imgUrl: B2bGallaryImg3,
      },
    },
  ],

  otherFeatures: {
    mainHeading: "Additional features",
    HexagonImg: HexagonImg,
    allFeatures: [
      {
        icon: B2bIcon1,
        heading: "Eliminate channel conflicts",
        description:
          "Create channel specific business rules and sales policies. Avoid conflicts. Improve Buyer Journeys.",
      },
      {
        icon: B2bIcon2,
        heading: "reach untapped markets",
        description:
          "offer products that are tailored to the needs of different industries or geographies, attracting new customers and enter new markets.",
      },
      {
        icon: B2bIcon3,
        heading: "Minimize sales complexity",
        description:
          "Accelerate sales cycle by reducing time to make a purchase decision with simple buy again and bulk order optio.",
      },
    ],
  },

  Featcherheading: "Explore more Solutions",
  LearnMoreSolution: [
    {
      Solution: "Marketplace solution",
      FeatcherLeftHead: "Intermediate between multiple buyers and sellers.",
      OrderManagementImg: B2cSolutionIcon1,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Solution: "D2C Commerce",
      FeatcherLeftHead: "Sell products directly to customers without intermediaries",
      OrderManagementImg: B2cSolutionIcon2,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Solution: "Omnichannel solution",
      FeatcherLeftHead: "Sell products seamlessly through multiple channels",
      OrderManagementImg: B2cSolutionIcon3,
      ArrowRightUpLine: ArrowRightUpLine,
    },
    {
      Solution: "B2B Commerce",
      FeatcherLeftHead: "Sell products or services to other companies",
      OrderManagementImg: B2cSolutionIcon4,
      ArrowRightUpLine: ArrowRightUpLine,
    },
  ],
};
