import { Profile1 } from "../../assets/images/Index";
import founder from "../../assets/images/about-us/MadanProfile.png";

export const Founder = {
    profileImg: founder,
    name: "Madan Gandam",
    designation: "Founder & CEO",
    linkedin: "https://www.linkedin.com/in/madankumargandam/",
    about: "Madan’s extensive experience in large-scale business solution implementations has given him valuable insights into the challenges that businesses face in this rapidly evolving digital landscape. His keen eye for recognizing what the market is asking for and businesses need led to the development of’ Honebi’.",
    extension: `‘Our aim is to equip businesses with future ready, innovative RaaS platform solutions that enable them to navigate everyday challenges, exceed evolving customer expectations and stay ahead of the competition in an omnichannel setting. We are committed to improving the ease of doing business through cutting edge technology’. This is where Madan comes from.
    With over two decades of experience in the technology space, Madan has had a front-row seat to the challenges faced by big IT in keeping up with the rapidly-evolving e-commerce landscape. As a Solution Architect with Satyam, Soltius ME, Tech Mahindra, IBM, DEWA, and others, Madan has been instrumental in crafting and implementing large-scale business solutions for market 
    giants such as Sony, Lenovo, Reliance Retail, and many others. Additionally, he has been associated with Dubai Electricity & Water Authority and Dubai Healthcare City, where he brought his expertise to the government sector.
    Madan has always been fascinated by how large retailers have leveraged technology to reach more customers and scale their businesses. However, he questioned whether small and medium-sized businesses had access to the same tools and resources. Were they being limited by the one-size-fits-all solutions offered by available platforms?
    Madan saw an opportunity to create a home-grown, end-to-end digital solution that could address the unique challenges facing small businesses and deliver an unparalleled customer experience. This drive to empower small businesses led Madan to step into this space and create a solution that would help them succeed in the digital age. And ‘Honebi’ was born.
    Born and raised in Nellore, Madan remains passionate about his roots. He is excited to bring Honebi to small retail and kirana stores in his hometown, empowering them to become the businesses of tomorrow.
    `
}