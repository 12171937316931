import Footer from "../../../shared/building-blocks/organisms/Footer";
import Navbar from "../../../shared/building-blocks/organisms/Navbar";
import Herosections from "../../../shared/building-blocks/molecules/Product-Components/Herosections";
import { frontendBuilderData } from "../../../adapters/frontend-experience-builder/frontend-experience-builder";
import Gallary from "../../../shared/building-blocks/molecules/Product-Components/Gallary";
import OtherFeatures from "../../../shared/building-blocks/molecules/Product-Components/AllFeatchers";
import MoreFeaturesSection from "../../../shared/building-blocks/molecules/Product-Components/MoreFeatures";

export const FrontendExperience = () => {
  return (
    <>
      <Navbar />
      <Herosections data={frontendBuilderData} name="FEB" />
      {frontendBuilderData?.Gallary.map((item, index) => (
        <Gallary data={item} name={"FEB"} type={index % 2 == 0 ? "TI" : "IT"} />
      ))}

      <OtherFeatures data={frontendBuilderData} name={"FEB"} />
      <MoreFeaturesSection data={frontendBuilderData} name={"FEB"} />

      <Footer />
    </>
  );
};
