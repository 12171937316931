import { ChatBot, HonebiLogo } from "../../assets/images/Index";


export const NavbarData = {
    brand:{
        logo: HonebiLogo,
    },
    menu: {
        "Products":["Digital Commerce Platform", "Front-End experience builder(CMS)","Product Information Management", "Catalog Management", "Order Management System",],
        // "CRM"
        "Solutions":["B2C Commerce", "B2B Commerce", "D2C Commerce", "Omnichannel Solution", "Marketplace Solution"],
        // "Industries":[],
        // "Tools":["Sales Assistance Solution", "Customer 360 Solution", "In Store VM Solution", "Van POS Solution"],
        "Editions":[],
        "About us":[]
    },
    Contact_us:{},
    QuickChat:{
        icon: ChatBot
    }
}