

export const TermAndServiceData = {

    Title : "Terms of service",
    left_head : "EXPLORE",
    left : ["Terms of service","Privacy Policy"],

    right_head : "LAST UPDATED ON 17 MARCH 2023",
    right_subhead : [
       "Honeysys knows that your privacy is important to you and takes user privacy very seriously. We are committed to protecting your personal data in accordance with this Privacy Policy. Please look at our privacy policy carefully to get a clear understanding of how we collect, and secure your Personal Information in accordance with this Policy.",

       "Honeysys reserves the right, at its sole discretion, to alter and update this Privacy Policy from time to time. We therefore invite you to review the current version of the Privacy Policy each time you return to our Sites."
    ],





  termAndService :  [
        {
            "id" : "1",
            "question" : "Information we collect and how we use it",
            "details" : "Honeysys might collect personal data from you such as your name, contact details, company or university affiliation, industry, region/country, relationship to Honeysys, the reason for contacting Honeysys, topics you are interested in and any other information you choose to share. By browsing our Sites or sending us information, you explicitly consent to Honeysys processing your personal data in accordance with this Privacy Policy. Honeysys might use your personal data to fulfil your request, send you marketing communications, process your job application or manage your membership of the Honeysys network.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Honeysys might collect personal data from you such as your name, contact details, company or university affiliation, industry, region/country, relationship to Honeysys, the reason for contacting Honeysys, topics you are interested in and any other information you choose to share. By browsing our Sites or sending us information, you explicitly consent to Honeysys processing your personal data in accordance with this Privacy Policy. Honeysys might use your personal data to fulfil your request, send you marketing communications, process your job application or manage your membership of the Honeysys network.",
                    "text2" : "Honeysys collects personal data from you when you request information from Honeysys and/or supply information through the Sites, such as when you fill out a subscription form or consulting expertise inquiry, opt-in to receive emails from Honeysys, agree to participate in surveys, join our network or apply for a role at Honeysys.",
                    "text3" : "Personal data we collect might include your name, e-mail address, title, occupation, company or university affiliation, industry, region/country, relationship to Honeysys, reason for contacting Honeysys, topics you are interested in, any other background information provided with an application and any other information contained in messages you send us. ",
                    "text4" : "By browsing our Sites and submitting this information to us, you explicitly agree to Honeysys using your personal data for the purposes and in the manner described in this Privacy Policy. Please do not send us any information you do not wish Honeysys to use.",
                    "text5" : "Honeysys will use your personal data to fulfil your request, provide you with the information you requested and/or send you marketing communications. If you have applied for a job at Honeysys, your data will be processed for the purposes of evaluating and processing your application and candidacy for the position to which you have applied. We may also use your information to analyse and improve our recruiting and on boarding processes as well as to communicate with you about Honeysys events and to send you publications that we think may be of interest to you. ",
                    "text6" : "Honeysys may collect personal data and may disclose such information to third party service providers in aggregate for marketing and promotional purposes. However, in these situations, we do not disclose to these entities any information that could be used to personally identify you. ",
                    "text7" : "You do not have to provide us with any personal data.  However, if you do not, we may be unable to handle your request or to provide you with the services you are requesting.",
                    "text8" : "Honeysys will keep your personal data for as long as needed to handle your request or for as long as we have a relationship with you. If you have applied for a role with Honeysys, Honeysys will keep your personal data associated with your application in our database. Please note that Honeysys is not responsible for any information you may disclose publicly in any chat rooms, message boards, or similar web pages, including those hosted on or linked to our Sites. You should keep in mind that, whenever you publicly disclose information about yourself online (for example, via message boards or chat rooms), such information could be collected and used by people whom you do not know. In addition, certain message boards and similar user forums may display IP addresses along with the message poster's name and message. Honeysys bears no responsibility for any action or policies of any third parties who collect any information users may disclose on the message boards, chat areas or other user forums, if any, on the Sites."
                }
            ]
        },
        {
            "id" : "2",
            "question" : "Use of cookies on the Sites",
            "details" : "Honeysys may use cookies or other technology to obtain certain types of information about you when you browse our site.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Honeysys may use cookies or other technology to obtain certain types of information about you when you browse our site.",
                    "text2" : "We may use cookies to keep track of your visit to our site, speed up navigation and improve your experience of the site.",
                    "text3" : "Our Sites may use cookies such as Google Analytics to carry out some analytics on and enhance our site. These cookies help us understand, for example, how our site is being used, which pages are the most visited or where people visiting our Sites are located.",
                    "text4" : "We may also use cookies to enable you to share content on social networking sites or for marketing and promotional purposes where you have subscribed to our marketing communications from Honeysys. ",
                    "text5" : "On our Sites we will use some necessary Cookies for the operation of our Sites and use of its features. They include, for example, Cookies that enable you to log into secure areas of our Sites. They enable us to remember some of your choices to speed up navigation and provide you with a secure site experience. Our Sites cannot function without such Cookies and they are therefore automatically set when you visit them.  You can set your browser to block or alert you about these Cookies, but some parts of our Sites will not work.",
                    "text6" : "The necessary Cookies are automatically set on your browser when you visit our Sites. These Cookies are required for our Sites to work properly and cannot be disabled using the “Manage Cookie Settings” window on our Sites. ",
                    "text7" : "You have the choice to accept all Cookies by clicking on “Accept all cookies” or manage your Cookie preferences by clicking on “Manage cookie settings” on the cookie banner.  Please note that certain functionalities of our Sites might not work if you choose to disable other Cookies.",
                    "text8" : "You can find more information about how to manage Cookies in the “help” section of your browser. "
                }
            ]
        },
        {
            "id" : "3",
            "question" : "Who we share your personal data with",
            "details" : "We may share your personal data with other companies of the Honeysys group as well as with other third parties that might be located in a different country than your country of residence. Honeysys will not share or sell your data to any third party for their own use.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "We may share your personal data with other companies of the Honeysys group as well as with other third parties that might be located in a different country than your country of residence. Honeysys will not share or sell your data to any third party for their own use. ",
                    "text2" : "We may share data with carefully selected third party service providers, such as marketing service, IT systems providers, recruitment service providers, data cleansing providers or event organizers, so they can provide services to Honeysys. With our advisors, other alumni members if you have chosen to take part in the Alumni network; and law enforcement authorities or other government bodies when we are required to do so. ",
                    "text3" : "Third parties other than law enforcement authorities or government bodies, will only handle your personal data on behalf of Honeysys for the purposes described in this Policy. Honeysys will not sell or otherwise share your personal data with such third parties for their own use. The companies of the Honeysys group and other third parties we share your information with may be located in other countries, including countries that do not offer the same protection to your personal data as the country in which you reside. Where that is the case, Honeysys ensures that your personal data is protected by using appropriate legal mechanisms. "
                }
            ]
        },
        {
            "id" : "4",
            "question" : "Links to other websites",
            "details" : "Our Sites may contain links to third party websites that are governed by their own terms and privacy policies. Honeysys does not bear any liability and responsibility for the content or your use of these website.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Our Sites may contain links to third party websites that are governed by their own terms and privacy policies. Honeysys does not bear any liability and responsibility for the content or your use of these website.",
                    "text2" : "The Sites may contain links to third party websites, including social network sites. Such websites are governed by their own terms of use and privacy policies, which you should review before browsing or submitting information to them. Honeysys is not responsible for the privacy practices of such websites. In addition, a link to a third party website does not mean that Honeysys endorses or accepts any responsibility for the content or the use of such website. Honeysys shall not bear any liability and responsibility for such content or for your use of these websites. "
                }
            ]
        },
        {
            "id" : "5",
            "question" : "Security of your personal data",
            "details" : "Honeysys has implemented appropriate technical and organizational security measures in order to protect your personal data from loss, misuse, alteration, or destruction.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Honeysys has implemented appropriate technical and organizational security measures in order to protect your personal data from loss, misuse, alteration, or destruction.",
                    "text2" : "Honeysys has implemented appropriate technical and organizational security measures in order to protect your personal data from loss, misuse, alteration, or destruction. Such measures include anonymizing data where possible. Authorized Honeysys personal and third parties mentioned above will only have access to your personal data to the extent they need it to do their job or provide their services. Despite these precautions, however, Honeysys cannot guarantee that unauthorized persons will not obtain access to your information."
                }
            ]
        },
        {
            "id" : "6",
            "question" : "Your Rights",
            "details" : "Depending on where you reside you might have the right to request access to, rectification or erasure of your personal data and to object to or ask for the restriction of the processing of your personal data. You might also have the right to withdraw your consent and request that we stop processing or delete your personal data.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Depending on where you reside you might have the right to request access to, rectification or erasure of your personal data and to object to or ask for the restriction of the processing of your personal data. You might also have the right to withdraw your consent and request that we stop processing or delete your personal data. ",
                    "text2" : "Depending on where you reside, you may be entitled to certain rights regarding the personal data Honeysys holds about you. For instance, you may have the right to request access to your information, obtain a copy of it as well as information about how we use it. You have the right to request that we correct incorrect, inaccurate or out-of-date information about you. You may also have the right to request that we stop processing your information.",
                    "text3" : "If you receive marketing communications from Honeysys, you can opt-out of receiving future emails from Honeysys. ",
                    "text4" : "In the event you no longer wish your data to be processed as described in this Policy, or if you wish to access, rectify, cancel or oppose such processing, please contact us at the information provided on Honeysys’ official website.",
                    "text5" : "Please note that we will take reasonable steps to check your identity before handling your request."
                }
            ]
        },
        {
            "id" : "7",
            "question" : "Job Applicants",
            "details" : "If you apply for a job at Honeysys, you will be asked to submit information to Honeysys such as your name, contact details, information about your education and work history and any other information that might be relevant for your application or that you choose to share with Honeysys. If you do not provide this information to us, we might not be able to process your application.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "If you apply for a job at Honeysys, you will be asked to submit information to Honeysys such as your name, contact details, information about your education and work history and any other information that might be relevant for your application or that you choose to share with Honeysys. If you do not provide this information to us, we might not be able to process your application.",
                    "text2" : "Honeysys will use this information to assess your application and candidacy for the position to which you have applied. Without limiting the generality of the foregoing, this may include: (i) evaluating your skills and experience in relation to the qualification required for the job to which you have applied; and (ii) tracking feedback and interactions we have with you throughout the recruiting process.",
                    "text3" : "We may also use your information when analysing our internal recruiting processes to determine (i) which recruit sources to focus on; (ii) how to improve integration and training programs for new hires with different educational backgrounds and work experience; and (iii) how to enhance our interview model to improve hiring quality.",
                    "text4" : "The information you provide may also be used to communicate with you about Honeysys events and to send you publications that we think may be of interest to you. We might share your personal data with other companies",
                    "text5" : "We might share your personal data with other companies in the Honeysys group as well as with third parties such as recruitment service providers, background check providers and IT system providers. These Honeysys companies and third parties might be located in a different country than your country of residence."
                }
            ]
        },
        {
            "id" : "8",
            "question" : "Target Audience",
            "details" : "Our Sites are not directed at children 16 years of age or younger. Honeysys, therefore, will not intentionally collect information about anyone under the age of 16, and requests that no such information be submitted to us.",
            "answer": [
                {
                    "id" : "1",
                    "text1" : "Our Sites are not directed at children 16 years of age or younger. Honeysys, therefore, will not intentionally collect information about anyone under the age of 16, and requests that no such information be submitted to us."
                }
            ]
        }
    ]
}