import { B2B, B2C, D2C, Omnichannel, MarketPlace, Pointer} from "../../assets/images/Index"
export const usecasesData = {
    title: "USE CASES",
    heading: "ONE product, MULTIPLE business models",
    usecaseList1: [
        {   
            icon: B2C,
            description: "Sell products or services direct to consumer.",
            button: "B2C Commerce"
        },{   
            icon: B2B,
            description: "Sell products or services direct to other companies.",
            button: "B2B Commerce"
        },{   
            icon: MarketPlace,
            description: "Intermediate between multiple buyers and sellers.",
            button: "Marketplace Solution"
        },
    ],
    usecaseList2:[
        {   
            icon: D2C,
            description: "Sell Products directly to consumers without intermediaries.",
            button: "D2C Commerce"
        },{   
            icon: Omnichannel,
            description: "Sell products seamlessly through multiple channels.",
            button: "Omnichannel Solution"
        },
    ],
    Pointer
}