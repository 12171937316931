import { conformist, eyesOnPrize, livingideas, Pointer, mainImg, powerfullWomen, shield } from "../../assets/images/Index";

export const careersAdapter = {
    router: "Careers",
    Pointer,
    heroSection: {
        title: "Careers",
        heading1: "Disruptors,",
        heading2: "Welcome!",
        description: "Join the pioneers of India’s First Unified Retail Operating System.",
        button: "Explore Opportunities",
        mainImg: mainImg
    },
    specialSection: [
        // {
        //     img: powerfullWomen,
        //     heading: "Powerful women Unstoppable together.",
        //     description: "Read more about the incredible work powerful women are doing here at Honebi and learn how they are unstoppable together.",
        //     Pointer
        // },
        {
            img: conformist,
            heading: "If you are NOT a conformist, then WE are a match!",
            description: "We have taken up the ‘ challenge of change’ so clearly, we are not looking for conformists, we are looking for disruptors.",
            Pointer
        }
    ],
    featureSection: [
        {
            img: livingideas,
            heading: "Freedom to live out your ideas.",
            description: "How else can we bring to the world ground breaking solutions? We trust our team mates to push boundaries and think out of the box. Is this what you’ve been missing? .",
            Pointer
        },
        {
            img: eyesOnPrize,
            heading: "Always, eyes on the prize.",
            description: "Our sights are set high, and nothing less will do. We have our founding team to thank for instilling this unwavering commitment to excellence and dogged perseverance.",
            Pointer
        },
    ],
    benefitsSection: {
        title: "Honebi Benefits",
        heading: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel nulla fringilla, ornare nulla eu, tempus elit.",
        list:[
            "Financial Incentives",
            "Health/Dental/Vision Insurance",
            "401(k) Plan With Match",
            "Work/Life Balance",
            "Professional Development",
            "Employee Discounts"
        ],
        shield,
    }
}