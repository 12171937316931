import React from 'react'
import { FiArrowUpRight } from 'react-icons/fi';
import { FaArrowDown } from 'react-icons/fa';
import { HeroSectionData } from '../../adapters/Index'
import "../../assets/styles/homePage/HeroSection.css"
import { Link } from 'react-router-dom';
const backgroundStyle = {
    backgroundImage: `url(${HeroSectionData.background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize:"cover"
    
}
const rightSectionBackground={
    backgroundImage: `url(${HeroSectionData.rightSection.background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
}
const HeroSection = () => {
    return (
        <div id='hero-section-container' style={backgroundStyle}>
            <div className='left-section'>
                <div className='content-section'>
                    <p>{ HeroSectionData.leftSection.title }</p>
                    <p>{ HeroSectionData.leftSection.description }</p>
                    <button><Link to="Digital Commerce Platform">{ HeroSectionData.leftSection.button }</Link><FiArrowUpRight/></button>
                </div>
            </div>
            <div className='navigate-arrow'><FaArrowDown/></div>
            <div className='right-section'>
                <img src={HeroSectionData.rightSection.background} alt="" />
            </div>
        </div>
    )
}

export default HeroSection
