import React from "react";
import "../../../assets/styles/footer/Footer.css";
import FooterList from "../molecules/FooterList";
import { FooterData } from "../../../adapters/Index";
import { Link } from "react-router-dom";
import NewsLetter from '../../../pages/HomePage/newsletter';
const Footerbackground = {
  backgroundImage: `url(${FooterData.FooterBackground})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left",
  backgroundSize: "cover",
};
const Footer = () => {
  return (
    <>
      <NewsLetter />
      <div className='demo-container' style={Footerbackground}>
        <p className='section-title'>{FooterData.demo.title}</p>
        <div>
          <img src={FooterData.demo.Pointer} alt="" />
          <p>{FooterData.demo.heading}</p>
        </div>
        <button
          onClick={() =>
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            })
          }
        ><Link to="/Contact">{FooterData.demo.button}</Link></button>
      </div>
      <div className='Footer_container'>
        <FooterList head="Products" subhead={FooterData.menu["Products"]} />
        <FooterList head="Solutions" subhead={FooterData.menu["Solutions"]} />
        {/* <FooterList head="Tools" subhead={FooterData.menu["Tools"]} /> */}
        <FooterList head="Company" subhead={FooterData.menu["Company"]} />
        {/* <FooterList head="Editions" subhead={FooterData.menu["Editions"]} /> */}
      </div>
      <div className='Footer_lower'>
        <div className='social_icon_img'>
          <a href="https://www.facebook.com/honebii" target="_blank">
          <div> <img src={FooterData?.icon?.facebook} alt="fb" className='social_icon' /></div>
          </a>
          
          <a href="https://www.instagram.com/honebi/" target="_blank">
          <div><img src={FooterData?.icon?.instagram} alt="ista" className='social_icon' /></div>
          </a>
          <a href="https://www.linkedin.com/company/honebi/" target="_blank">
          <div> <img src={FooterData?.icon?.linkdin} alt="ldf" className='social_icon' /></div>
          </a>
        </div>
        <div className="footer_bottom_links">
          {
            FooterData?.links?.map((link, index) => {
              { console.log(link) }
              return (
                <p className='footer-link-title' onClick={() =>
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                } key={index}><Link to={`/${link}`}>{link}</Link></p>
              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default Footer;
